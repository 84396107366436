.avatar {
  width: 75px;
  height: 50px;
  position: relative;
  margin: 0;
}

#canvas,
#overlayCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 75%;
  filter: blur(6px);
}

.svgElem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
