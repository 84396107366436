@tailwind base;
@tailwind components;
@tailwind utilities;

.blinking-cursor::after {
  content: "|";
  animation: blink 1s step-end infinite;
}

@font-face {
  font-family: "font-default";
  src: url("./fonts/WPP-Regular.woff");
}

@font-face {
  font-family: "font-inter-light";
  src: url("./fonts/Inter-Light.ttf");
}

@font-face {
  font-family: "font-ibm";
  src: url("./fonts/IBM-Regular.ttf");
}

@font-face {
  font-family: "font-ibm-bold";
  src: url("./fonts/IBM-Bold.ttf");
}

@keyframes blink {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

#question-box-buttons {
  line-height: 130%;
}

/* Content Rail  */
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 70%;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
}

/* Custom Scrollbar Styles */
/* Scrollbar container */
::-webkit-scrollbar {
  width: 12px;
  background: transparent;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Hide scrollbar buttons */
::-webkit-scrollbar-button {
  display: none;
}

/* Firefox scrollbar */
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

@-webkit-keyframes autofill {
  0%,
  100% {
    background: transparent;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent !important;
  -webkit-box-shadow: transparent !important;
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

/* Brians CSS - Convert to tailwin */
:root {
  --paddingOffset: 30px;
}
/* Prevent page-level scrollbars */
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Hide any scrollbars on the entire page */
}

/* Main Container */
.mainContainer {
  width: 100%;
  height: calc(100% - 65px);
  overflow: hidden; /* Ensure no overflow */
  position: fixed;
  bottom: 0;
  left: 0;
}

@media (max-width: 1279px) {
  .mainContainer {
    height: calc(100% - 58px);
  }
}

/* Scroll Container - Only this container has vertical scrolling */
.scrollContainer {
  width: 100%;
  height: 100%;
  overflow-y: scroll; /* Enable vertical scrollbar */
  overflow-x: hidden; /* Prevent horizontal scrollbar */
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  pointer-events: all;
}

/* Scroller */
.scroller {
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  overflow: hidden; /* Ensure no overflow */
}

/* Templates Container */
.templates_Container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  perspective: 1000px;
  overflow: hidden;
  pointer-events: none;
  margin-top: 60px;
}

@media (max-width: 767px) {
  .templates_Container {
    margin-top: 120px;
  }
}

/* Template Wrapper */
.template_wrapper {
  width: 95%;
  height: calc(100% - var(--paddingOffset));
  position: absolute;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Smooth appearance */
}

@media (max-width: 767px) {
  .template_wrapper {
    width: 100%;
  }
}

/* Template */
.template {
  position: relative;
  font-size: 70px;
  width: 100%;
  text-align: left;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden; /* Ensure no overflow */
}

/* Followups Container */
.followupsContainer {
  position: absolute;
  width: 95%;
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition:
    opacity 0.5s ease-in-out,
    visibility 0.5s ease-in-out;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  overflow: hidden; /* Ensure no overflow */
  pointer-events: auto;
}

@media (max-width: 767px) {
  .followupsContainer {
    width: 100%;
    height: initial !important;
  }
}

.followupsContainer.hidden {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.5s ease-in-out,
    visibility 0.5s ease-in-out;
}

/* Background blur */
.backgroundBlur {
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden; /* Ensure no overflow */
}

/* Keyframes for Followups Animation */
@keyframes followup_moveUp {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 65px;
  }
}

@keyframes followup_moveDown {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -100%;
  }
}

@media (max-width: 1279px) {
  @keyframes followup_moveUp {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 58px;
    }
  }
}

/* Scrollbar container */
::-webkit-scrollbar {
  width: 12px;
  background: transparent;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Hide scrollbar buttons */
::-webkit-scrollbar-button {
  display: none;
}

/* Firefox scrollbar */
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}
